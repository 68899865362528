import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { SdirTabs, Loader } from '@sdir/sds';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { VesselHeaderCard } from '@sdir/blueprint.et/lib/components';
import { SubjectAreaOptionDto } from '@sdir/httpclient/lib/clients/core/requirement';
import ApiError from '../../../types/ApiError';
import RegulationsSection from '../../Organisms/RegulationSection';
import { ActivitySection, SurveyScopeSection } from '../../Molecules';
import { AccessSection, InspectionSection } from '../../Organisms';
import { Routes } from '../../Templates/Routes';
import {
  vesselTabOptions,
  vesselTabOptionsHiddenRequirementList
} from '../../Templates/Routes/constants';
import useRequirements from '../../../services/hooks/useRequirements';
import useCertificate from '../../../services/hooks/useCertificate';
import IncidentContainer from '../../Organisms/IncidentContainer';
import CertificateSection from '../../Organisms/CertificateSection';
import VesselCardSectionContainer from '../../Organisms/VesselCardSectionContainer';
import useETCaseworker from '../../../services/hooks/useETCaseworker';

interface VesselPageProps {
  requirementsListTabEnabled?: boolean;
  surveyTabEnabled?: boolean;
}

const VesselPage: React.FC<VesselPageProps> = ({
  requirementsListTabEnabled,
  surveyTabEnabled
}) => {
  const { tab, projectuid, surveyuid } = useParams() as {
    projectuid: string;
    tab: string;
    surveyuid: string;
  };
  const [currentSelectedSubjectarea, setCurrentSelectedSubjectarea] = useState<string>();
  const [includeNotRelevantActivities, setIncludeNotRelevantActivities] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  let tabOptions = requirementsListTabEnabled
    ? Object.keys(vesselTabOptions)
    : Object.keys(vesselTabOptionsHiddenRequirementList);

  if (!surveyTabEnabled) {
    tabOptions = tabOptions.filter(t => t !== 'inspection');
  }

  const getTabIndex = () => tabOptions.findIndex(el => el === tab);

  const {
    vesselLoading,
    vesselError,
    vessel,
    getVessel,
    filteredReqList,
    filteredReqListLoading,
    getFilteredReqs,
    requirementsOverview,
    requirementsOverviewLoading,
    getActivityList,
    getActivityListLoading,
    activityList,
    getSubjectAreas,
    getSubjectAreasLoading
  } = useRequirements(projectuid);

  const { getEtCaseWorkers } = useETCaseworker();

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);
  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);
  const subjectAreas: SubjectAreaOptionDto[] = useSelector(
    (state: any) => state.appState.subjectAreas
  );

  useEffect(() => {
    if (!etCaseWorkers && !etCaseWorkersLoading) {
      getEtCaseWorkers();
    }

    const autoselectSubjectarea = localStorage.getItem('newActivityCurrentSubjectarea');
    if (autoselectSubjectarea) {
      setCurrentSelectedSubjectarea(autoselectSubjectarea);
      localStorage.removeItem('newActivityCurrentSubjectarea');
    }
  }, []);

  useEffect(() => {
    if (!vessel && projectuid) {
      getVessel();
    }
    if (vessel && projectuid && vessel.uid !== projectuid) {
      getVessel();
      // For survey scope tab, we are getting suggested survey scope activities
      getPlannedVesselCertificates();
      getVesselCertificates();
    }
    if (tab === 'activitylist') {
      if (!subjectAreas) {
        getSubjectAreas();
      }
      getActivityList();
    }
  }, [projectuid, vessel, tab]);

  const {
    getPlannedVesselCertificates,
    getSpecificationsOptions,
    getVesselCertificates,
    loading
  } = useCertificate(projectuid);
  const specificationOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );
  const vesselCertificates = useSelector((state: any) => state.appState.vesselCertificates);
  const plannedCertificates = useSelector((state: any) => state.appState.plannedVesselCertificates);

  useEffect(() => {
    if (['activitylist', 'certificate', 'inspection'].includes(tab)) {
      if (!plannedCertificates) {
        (async () => {
          await getPlannedVesselCertificates();
        })();
      }
    }

    if (!specificationOptions) {
      (async () => {
        await getSpecificationsOptions();
      })();
    }

    if (['certificate'].includes(tab) && vessel && !vesselCertificates) {
      (async () => {
        await getVesselCertificates(vessel.vesselUid);
      })();
    }
  }, [tab, vessel]);

  if (vesselError) {
    throw new ApiError(vesselError.response);
  }

  const tabTitlesWithoutRequirements = surveyTabEnabled
    ? [
        intl.formatMessage({ id: 'vesselcard.tab.title' }),
        intl.formatMessage({ id: 'allekravpage.tabs.activitylist' }),
        intl.formatMessage({ id: 'allekravpage.tabs.projectmanagement' }),
        intl.formatMessage({ id: 'survey.tab.title' }),
        intl.formatMessage({ id: 'incidents.tab.title' }),
        intl.formatMessage({ id: 'certificates.tab.title' })
      ]
    : [
        intl.formatMessage({ id: 'vesselcard.tab.title' }),
        intl.formatMessage({ id: 'allekravpage.tabs.activitylist' }),
        intl.formatMessage({ id: 'allekravpage.tabs.projectmanagement' }),
        intl.formatMessage({ id: 'incidents.tab.title' }),
        intl.formatMessage({ id: 'certificates.tab.title' })
      ];

  const allTabTitles = [intl.formatMessage({ id: 'allekravpage.tabs.allrequirements' })].concat(
    tabTitlesWithoutRequirements
  );

  const onTabClick = (key: number) => {
    if (tabOptions[key] === 'activitylist') getActivityList();
    navigate(generatePath(Routes.vesselWorkspace, { tab: tabOptions[key], projectuid }));
  };

  const handleSelectedSubjectArea = subjectarea => {
    setCurrentSelectedSubjectarea(subjectarea);
  };

  const renderChildren = () => {
    switch (tabOptions[getTabIndex()]) {
      case 'activitylist':
        return (
          <ActivitySection
            menuListLoading={
              getSubjectAreasLoading ||
              (!activityList?.activities?.length && getActivityListLoading)
            }
            subjectAreas={subjectAreas}
            includeNotRelevantActivities={includeNotRelevantActivities}
            setIncludeNotRelevantActivities={setIncludeNotRelevantActivities}
            activityList={activityList}
            activitiesLoading={getActivityListLoading}
            getActivityList={getActivityList}
            currentSelectedSubjectarea={currentSelectedSubjectarea}
            requirementsListTabEnabled={requirementsListTabEnabled}
            handleSelectedSubjectArea={handleSelectedSubjectArea}
          />
        );
      case 'inspection':
        if (surveyuid !== undefined) {
          return <SurveyScopeSection projectUid={projectuid} surveyUid={surveyuid} />;
        }
        return <InspectionSection etCaseWorkers={etCaseWorkers} vesselUid={vessel?.vesselUid} />;
      case 'requirementlist': {
        return (
          <RegulationsSection
            filteredRequirements={filteredReqList}
            listMenuRequirements={requirementsOverview}
            getFilteredRequirements={eliref => getFilteredReqs(eliref)}
            loading={filteredReqListLoading}
          />
        );
      }
      case 'projectmanagement':
        return <AccessSection vessel={vessel} />;
      case 'incident':
        return <IncidentContainer />;
      case 'certificate':
        return <CertificateSection loading={loading} />;
      case 'vesselcard':
        if (vessel?.vesselUid) {
          return (
            <VesselCardSectionContainer vesselUid={vessel.vesselUid} projectUid={projectuid} />
          );
        }
        return null;
      default:
        return null;
    }
  };

  if (vesselLoading || requirementsOverviewLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Container>
      {vessel && <VesselHeaderCard vessel={vessel} />}
      <TabContainer>
        <SdirTabs
          activeKey={getTabIndex()}
          titles={requirementsListTabEnabled ? allTabTitles : tabTitlesWithoutRequirements}
          eachTabClick={onTabClick}
        />
      </TabContainer>

      {renderChildren()}
    </Container>
  );
};

export default VesselPage;

const TabContainer = styled.div`
  height: 6rem;
  margin-top: 5rem;
`;

const LoaderContainer = styled.div`
  grid-area: 4 / 8;
`;

const Container = styled.section`
  width: 100%;
  max-width: 188rem;
  margin: 4rem auto 0 auto;
`;
