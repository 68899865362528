import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataTable, Loader, MultiSelect, SdirButton, styles, Tag, TextLink } from '@sdir/sds';
import { useDataSorting, useToast } from '@sdir/utilities/lib/hooks';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { InvitedUser, ManagedUser } from '@sdir/httpclient/lib/clients/core/authorization';
import UserAdministrationAddModal from '@sdir/blueprint.et/lib/components/Organisms/UserAdministrationModal/UserAdministrationAddModal';
import { PermissionEntity } from '@sdir/blueprint.et/lib/components/Organisms/UserAdministrationModal/userAdministrationModalUtilities';
import UserAdministrationEditModal from '@sdir/blueprint.et/lib/components/Organisms/UserAdministrationModal/UserAdministrationEditModal';
import { userPermissionApi, vesselsApi } from '../../../httpclient';
import { useFeatureToggles } from '../../../services/hooks/useFeatureToggles';

interface ManagedUserWithInvitationFlag extends ManagedUser {
  isInvitedUser?: boolean;
}

const UserAdministrationPage = () => {
  const fm = useIntl().formatMessage;
  const { setError, setSuccess } = useToast();
  const { organizationNumber } = useParams<{ organizationNumber: string }>();
  const [vesselFilters, setVesselFilter] = useState<string[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ManagedUser | undefined>();
  const [selectedEntity, setSelectedEntity] = useState<PermissionEntity | undefined>();
  const [resendInvitationData, setResendInvitationData] = useState<InvitedUser | undefined>();
  const [selectedVesselAddedToInvite, setSelectedVesselAddedToInvite] = useState<
    string | undefined
  >();

  const { result: unleashActiveToggles, loading: featureTogglesLoading } = useFeatureToggles();

  const { callApi: resendInvite, loading: resendInviteLoading } = useApi({
    apiFunction: invitationUid =>
      userPermissionApi.v1UserpermissionResendinvitationInvitationUidPut(invitationUid),
    successCallback: () => setSuccess(fm({ id: 'useradmin.resendInvite.toast.success' })),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'useradmin.resendInvite.toast.error' })}: ${err}`);
      }
    }
  });

  useEffect(() => {
    if (organizationNumber) {
      getOrganizationVessels(organizationNumber);
    }
  }, [organizationNumber]);

  const {
    callApi: getOrganizationVessels,
    result: organizationVessels,
    loading: getOrganizationVesselsLoading
  } = useApi({
    apiFunction: payload => vesselsApi.v1VesselsGetorganizationvesselsOrganizationIdGet(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'error.load' }, { error: 'fartøy' })}: ${err}`);
      }
    },
    successCallback: res => {
      const vesselIds = res.vessels?.map(vessel => vessel.uid) ?? [];

      if (vesselIds.length) {
        getUsers(vesselIds);
        getInvitedUsers(vesselIds);
      }
    }
  });

  const { callApi: getUsers, result: users, loading: getUsersLoading } = useApi({
    apiFunction: payload =>
      userPermissionApi.v1UserpermissionGetentitiesusersEntityUidsPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'error.load' }, { error: 'brukere' })}: ${err}`);
      }
    }
  });

  const {
    callApi: getInvitedUsers,
    result: invitedUsers,
    loading: getInvitedUsersLoading
  } = useApi({
    apiFunction: payload =>
      userPermissionApi.v1UserpermissionGetentitiesinvitedusersEntityUidsPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'error.load' }, { error: 'inviterte brukere' })}: ${err}`);
      }
    }
  });

  const getInvitedUsersAndUsers = () => {
    const vesselIds = organizationVessels?.vessels?.map(vessel => vessel.uid) ?? [];
    getUsers(vesselIds);
    getInvitedUsers(vesselIds);
  };

  const mergedUsersAndInvitedUsers: ManagedUserWithInvitationFlag[] = useMemo(() => {
    const listOfUsers = (
      invitedUsers?.invitedUsers?.map(iu => ({
        ...iu,
        uid: iu.uid ?? undefined,
        isInvitedUser: true
      })) ?? []
    ).concat(
      users?.users?.map(u => ({ ...u, uid: u.uid ?? undefined, isInvitedUser: false })) ?? []
    );

    return listOfUsers;
  }, [users, invitedUsers, vesselFilters]);

  const getVesselName = useCallback(
    entityUid => {
      if (!organizationVessels?.vessels) {
        return '';
      }

      const foundVessel = organizationVessels.vessels.find(vessel => vessel.uid === entityUid);

      if (foundVessel) {
        return foundVessel.vesselName;
      }

      return entityUid;
    },
    [organizationVessels]
  );

  const vesselFilterOptions = useMemo(() => {
    if (!organizationVessels?.vessels) {
      return [];
    }

    return organizationVessels.vessels
      .map(vessel => ({
        value: vessel.uid ?? '',
        name: vessel.vesselName ?? ''
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [organizationVessels]);

  const filteredUserList = useMemo(() => {
    if (!vesselFilters.length) {
      return mergedUsersAndInvitedUsers;
    }

    return mergedUsersAndInvitedUsers.filter(
      user => user.entities && user.entities.some(ent => vesselFilters.includes(ent.uid ?? ''))
    );
  }, [mergedUsersAndInvitedUsers, vesselFilters]);

  const columns = [
    {
      label: fm({ id: 'useradmin.table.column.name' }),
      key: 'name'
    },
    {
      label: fm({ id: 'useradmin.table.column.email' }),
      key: 'email'
    },
    {
      label: '',
      key: 'resendinvite'
    },
    {
      label: fm({ id: 'useradmin.table.column.vesselaccess' }),
      key: 'entities'
    },
    {
      label: '',
      key: 'temp'
    }
  ];

  const { sortedData, setSortedBy } = useDataSorting<any>(filteredUserList, columns);

  return (
    <Container>
      <Header>
        <Title>{fm({ id: 'useradmin.header' })}</Title>
        <HeaderButtonWrapper>
          <SdirButton
            type="primary"
            disabled={
              getUsersLoading || getInvitedUsersLoading || !organizationVessels?.vessels?.length
            }
            text={fm({ id: 'useradmin.button.label.newuser' })}
            onClick={() => {
              resendInvitationData && setResendInvitationData(undefined);
              setShowAddModal(true);
            }}
          />
          <MultiSelect
            size="40rem"
            disabled={getUsersLoading || getInvitedUsersLoading}
            defaultVal={fm({ id: 'useradmin.vessel.filter.label' })}
            value={vesselFilters}
            options={vesselFilterOptions}
            handleChange={vesselUid => {
              const alreadyExists = vesselFilters.findIndex(el => el === vesselUid) >= 0;
              if (alreadyExists) {
                const newState = vesselFilters.filter(el => el !== vesselUid);
                setVesselFilter(newState);
              } else {
                setVesselFilter([...vesselFilters, vesselUid]);
              }
            }}
            searchable
          />
        </HeaderButtonWrapper>
      </Header>
      {getUsersLoading || getOrganizationVesselsLoading || featureTogglesLoading ? (
        <Loader />
      ) : (
        <Content>
          <TableWrapper>
            <StyledDataTable
              colWidth={['20vw', '20vw', '8vw', '40vw']}
              columns={columns}
              data={sortedData.map(item => {
                return {
                  ...item,
                  entities: (
                    <VesselTagWrapper>
                      {item.entities.map(entity => (
                        <TagWrapper key={entity.uid}>
                          <Tag
                            label={getVesselName(entity.uid)}
                            icon={<FontAwesomeIcon icon={faPenToSquare as IconProp} />}
                            onActionClick={() => {
                              if (item.isInvitedUser) {
                                setShowAddModal(true);
                                setResendInvitationData(
                                  (invitedUsers?.invitedUsers ?? []).find(
                                    invitedUser => invitedUser.uid === item.uid
                                  )
                                );
                              } else {
                                setShowEditModal(true);
                                setSelectedUser(item);
                                setSelectedEntity({
                                  value: entity.uid,
                                  name: getVesselName(entity.uid)
                                });
                              }
                            }}
                          />
                        </TagWrapper>
                      ))}
                      {(organizationVessels?.vessels ?? [])
                        .filter(vessel => !item.entities.some(ent => ent.uid === vessel.uid))
                        .map(v => (
                          <TagWrapper key={v.uid}>
                            <Tag
                              type="add"
                              label={v.vesselName ?? ''}
                              icon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                              onActionClick={() => {
                                if (item.isInvitedUser) {
                                  setSelectedVesselAddedToInvite(v.uid);
                                  setResendInvitationData(
                                    (invitedUsers?.invitedUsers ?? []).find(
                                      invitedUser => invitedUser.uid === item.uid
                                    )
                                  );
                                  setShowAddModal(true);
                                  setSelectedUser(item);
                                } else {
                                  setShowEditModal(true);
                                  setSelectedUser(item);
                                  setSelectedEntity({
                                    value: v.uid ?? '',
                                    name: v.vesselName ?? ''
                                  });
                                }
                              }}
                            />
                          </TagWrapper>
                        ))}
                    </VesselTagWrapper>
                  ),
                  resendinvite: item.isInvitedUser ? (
                    <TextLink
                      text={fm({ id: 'useradmin.table.newinvite' })}
                      disabled={resendInviteLoading}
                      onClick={() => resendInvite(item.uid)}
                    />
                  ) : (
                    ''
                  )
                };
              })}
              onHeaderClick={setSortedBy}
              sortableKeys={['name', 'email']}
            />
          </TableWrapper>
          {showAddModal && (
            <UserAdministrationAddModal
              setShowAddModal={shouldShow => {
                if (!shouldShow && selectedVesselAddedToInvite) {
                  setSelectedVesselAddedToInvite(undefined);
                }
                setShowAddModal(shouldShow);
              }}
              vessels={vesselFilterOptions}
              getInvitedUsersAndUsers={getInvitedUsersAndUsers}
              resendInvitationData={resendInvitationData}
              selectedVesselAddedToInvite={selectedVesselAddedToInvite}
              unleashActiveToggles={unleashActiveToggles}
            />
          )}
          {showEditModal && (
            <UserAdministrationEditModal
              setShowEditModal={setShowEditModal}
              selectedEntity={selectedEntity}
              selectedUser={selectedUser}
              getInvitedUsersAndUsers={getInvitedUsersAndUsers}
              unleashActiveToggles={unleashActiveToggles}
            />
          )}
        </Content>
      )}
    </Container>
  );
};

export default UserAdministrationPage;

const Container = styled.div`
  margin: 5rem 26rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.font.text85};

  @media screen and (max-width: ${({ theme }) => theme.screen.large}) {
    margin: 5rem 10rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.screen.medium}) {
    margin: 5rem 4rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  flex-wrap: wrap;
`;

const VesselTagWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TagWrapper = styled.div`
  & > div {
    gap: 1rem;
  }

  path {
    fill: ${styles.colors.primary};
  }
`;

const StyledDataTable = styled(DataTable)`
  padding: 0;

  tbody tr:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary.grey5};
  }
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
`;

const Content = styled.div``;
