import styled from 'styled-components';
import { Loader } from '@sdir/sds';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetProjectResult } from '@sdir/httpclient/lib/clients/et/construction';
import React from 'react';
import SdirCoordinator from '../../Molecules/Access/SdirCoordinator';
import SdirTeam from '../../Molecules/Access/SdirTeam';
import CustomerTeam from '../../Molecules/Access/CustomerTeam';
import useAccess from './useAccess';

interface Props {
  vessel: GetProjectResult | undefined;
}

const AccessSection: React.FC<Props> = ({ vessel }) => {
  const { projectuid } = useParams() as { projectuid: string };

  const {
    teamMembers,
    teamMembersLoading,
    reloadTeamMembers,
    sdirTeam,
    sdirTeamLoading,
    reloadSdirTeam
  } = useAccess(projectuid, vessel?.vesselUid);

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);
  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);

  if (teamMembersLoading || etCaseWorkersLoading || sdirTeamLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Container>
      {etCaseWorkers && (
        <SdirTeamContainer>
          {sdirTeam?.coordinators && (
            <SdirCoordinator
              loading={etCaseWorkersLoading}
              etCaseWorkers={etCaseWorkers}
              coordinator={sdirTeam.coordinators}
              reloadSdirTeam={reloadSdirTeam}
            />
          )}
          {sdirTeam?.team && (
            <SdirTeam
              loading={etCaseWorkersLoading}
              etCaseWorkers={etCaseWorkers}
              sdirTeam={sdirTeam.team}
              reloadSdirTeam={reloadSdirTeam}
            />
          )}
        </SdirTeamContainer>
      )}
      {teamMembers && (
        <CustomerTeamContainer>
          <CustomerTeam
            reloadTeamMembers={reloadTeamMembers}
            teamMembers={teamMembers?.users ?? []}
            loading={teamMembersLoading}
            vessel={vessel}
          />
        </CustomerTeamContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 5rem;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  grid-area: 4 / 1 / auto / fill;
  align-items: self-start;
`;

const LoaderContainer = styled.div`
  grid-area: 4 / 7;
`;

const SdirTeamContainer = styled.div`
  grid-auto-columns: 1;
`;

const CustomerTeamContainer = styled.div`
  grid-auto-columns: 2;
`;

export default AccessSection;
