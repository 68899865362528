/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SdirTabs } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import ConstructionNotice from '../../Organisms/EtDashboard/ConstructionNotice';
import TaskTable from '../../Organisms/EtDashboard/TasksTable';
import Page from '../../Templates/Page';
import {
  getMyProjectsTasksApi,
  constructionNoticeApi,
  specificationsApi
} from '../../../httpclient';

interface CaseWorkerPageProps {
  requirementsListTabEnabled?: boolean;
}

export enum TabOptions {
  'MYTASKS' = 0,
  'ALLTASKS' = 1,
  'CONSTRUCTIONNOTICES' = 2
}

export const caseWorkerTabStorageKey = 'CaseWorkerTab';

const CaseWorkerPage: React.FC<CaseWorkerPageProps> = () => {
  const tabFromStorage = localStorage.getItem(caseWorkerTabStorageKey);
  const defaultTab = tabFromStorage ? parseInt(tabFromStorage, 10) : TabOptions.MYTASKS;
  const [activeTab, setActiveTab] = useState<number>(defaultTab);

  const intl = useIntl();
  const { setError } = useToast();
  const tabTitles = [
    intl.formatMessage({ id: 'caseworker.page.tab.mytasks' }),
    intl.formatMessage({ id: 'caseworker.page.tab.alltasks' }),
    intl.formatMessage({ id: 'caseworker.page.tab.constructionnotice' })
  ];

  const {
    result: submittedConstructionNotices,
    loading: submittedConstructionNoticesLoading,
    error: submittedConstructionNoticesError
  } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v1ConstructionnoticeGetconstructionnoticessubmittedforreviewGet(),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(
          `${intl.formatMessage({ id: 'error.load' }, { error: 'construction notices' })}: ${err}`
        );
      }
    }
  });

  const { result: specificationOptions, loading: specificationOptionsLoading } = useApi({
    apiFunction: () => specificationsApi.v1SpecificationsGetvesselspecificationoptionsGet(),
    callApiOnInit: true
  });

  const {
    result: unsubmittedConstructionNotices,
    error: unsubmittedConstructionNoticesError,
    callApi: getUnsubmittedConstructionNotices
  } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v1ConstructionnoticeGetmyunsubmittedconstructionnoticesGet(),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(
          `${intl.formatMessage(
            { id: 'error.load' },
            { error: 'unsubmitted construction notices' }
          )}: ${err}`
        );
      }
    }
  });

  const getVesselTypeLabel = useCallback(
    vesselTypeUri => {
      if (specificationOptions && specificationOptions.vesselTypes) {
        const matchingOption = specificationOptions.vesselTypes.find(
          vt => vt.value === vesselTypeUri
        );

        if (matchingOption) {
          return matchingOption.label;
        }
      }

      return vesselTypeUri;
    },
    [specificationOptions]
  );

  const {
    result: myProjectTasksResult,
    loading: myProjectTasksLoading,
    callApi: reloadMyProjectTasks
  } = useApi({
    apiFunction: () => getMyProjectsTasksApi.v1GetmyprojectstasksGetprojecttasksGet(),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(
          `${intl.formatMessage({ id: 'error.load' }, { error: 'mine prosjekt oppgaver' })}: ${err}`
        );
      }
    }
  });

  const { result: myTasksResult, loading: myTasksLoading, callApi: reloadMyTasks } = useApi({
    apiFunction: () => getMyProjectsTasksApi.v1GetmyprojectstasksGetmytasksGet(),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'mine oppgaver' })}: ${err}`);
      }
    }
  });

  const onTabClick = (key: number, e: any) => {
    e.preventDefault?.();
    setActiveTab(key);
    localStorage.setItem(caseWorkerTabStorageKey, key.toString());
  };

  const handleGetUnsubmittedConstructionNotices = () => {
    getUnsubmittedConstructionNotices();
  };

  return (
    <Page
      error={submittedConstructionNoticesError || unsubmittedConstructionNoticesError}
      loading={
        submittedConstructionNoticesLoading ||
        myProjectTasksLoading ||
        myTasksLoading ||
        specificationOptionsLoading
      }
    >
      <Container>
        <Title>{intl.formatMessage({ id: 'dashboard.caeworkerpage.header' })}</Title>
        <SdirTabs defaultActiveKey={activeTab} titles={tabTitles} eachTabClick={onTabClick} />
        {/* Mine oppgaver */}
        {activeTab === TabOptions.MYTASKS && myTasksResult && myTasksResult.length > 0 && (
          <TaskTable
            tasksResult={myTasksResult}
            reloadTasks={() => {
              reloadMyTasks();
              reloadMyProjectTasks();
            }}
          />
        )}
        {activeTab === TabOptions.MYTASKS && myTasksResult && myTasksResult.length === 0 && (
          <>{intl.formatMessage({ id: 'caseworker.page.tasks.notasksfound' })}</>
        )}

        {/* Alle oppgaver */}
        {activeTab === TabOptions.ALLTASKS &&
          myProjectTasksResult &&
          myProjectTasksResult.length > 0 && (
            <TaskTable
              tasksResult={myProjectTasksResult}
              reloadTasks={() => {
                reloadMyTasks();
                reloadMyProjectTasks();
              }}
            />
          )}
        {activeTab === TabOptions.ALLTASKS &&
          myProjectTasksResult &&
          myProjectTasksResult.length === 0 && (
            <>{intl.formatMessage({ id: 'caseworker.page.tasks.notasksfound' })}</>
          )}

        {/* Byggemelding */}
        {submittedConstructionNotices && activeTab === TabOptions.CONSTRUCTIONNOTICES && (
          <ConstructionNotice
            unsubmittedConstructionNotices={(unsubmittedConstructionNotices ?? []).map(cn => ({
              ...cn,
              vesselType: getVesselTypeLabel(cn.vesselType)
            }))}
            submittedConstructionNotices={(submittedConstructionNotices ?? []).map(cn => ({
              ...cn,
              vesselType: getVesselTypeLabel(cn.vesselType)
            }))}
            getUnsubmittedConstructionNotices={handleGetUnsubmittedConstructionNotices}
          />
        )}
      </Container>
    </Page>
  );
};

const Container = styled.section`
  width: 100%;
  max-width: 188rem;
  margin: 2rem auto 0 auto;
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(3, auto);
  column-gap: 4rem;
  row-gap: 4rem;
  height: 100%;
  tbody {
    tr {
      cursor: pointer;
      :hover {
        background-color: rgb(242, 245, 247);
      }
    }
  }
`;

const Title = styled.p`
  text-transform: uppercase;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.colors.primary7};
`;

export default CaseWorkerPage;
