import { VesselSpecificationOptionsResult } from '@sdir/httpclient/lib/clients/core/requirement';
import {
  GetPlannedProjectCertificatesResult,
  GetVesselCertificateResult,
  GetVesselDataAreaV2Result
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { GetVesselDataResultV3 } from '@sdir/httpclient/lib/clients/core/commonvessel';
import { User } from '@sdir/httpclient/lib/clients/core/authorization';
import {
  SET_VESSEL,
  SET_CURRENT_REQUIREMENT,
  SET_VESSEL_BUILDING_PROJECT,
  SET_HISTORY,
  UPDATE_ACTIVITY_STATUS,
  SET_PLANNED_VESSEL_CERTIFICATES,
  SET_SPECIFICATION_OPTIONS,
  SET_PROJECT_UID,
  SET_ET_CASEWORKERS,
  SET_ET_CASEWORKERS_LOADING,
  SET_VESSEL_CERTIFICATES,
  SET_VESSEL_CARD_DATA,
  SET_VESSEL_CARD_DATA_LOADING
} from '../constants/action-types';
import { Vessel, VesselBuildingProject } from '../../types/index.d';

export const setVessel = (payload: Vessel) => {
  return { type: SET_VESSEL, payload };
};

export const setCurrentRequirement = (payload: any) => {
  return { type: SET_CURRENT_REQUIREMENT, payload };
};

export const setVesselBuildingProject = (payload: VesselBuildingProject) => {
  return { type: SET_VESSEL_BUILDING_PROJECT, payload };
};

export const setHistory = (payload: string) => {
  return { type: SET_HISTORY, payload };
};

export const setActivityStatus = payload => {
  return { type: UPDATE_ACTIVITY_STATUS, payload };
};

export const setPlannedVesselCertificates = (
  payload: GetPlannedProjectCertificatesResult | undefined
) => {
  return { type: SET_PLANNED_VESSEL_CERTIFICATES, payload };
};

export const setVesselCertificates = (payload: GetVesselCertificateResult[] | undefined) => {
  return { type: SET_VESSEL_CERTIFICATES, payload };
};

export const setSpecificationOptions = (payload: VesselSpecificationOptionsResult | undefined) => {
  return { type: SET_SPECIFICATION_OPTIONS, payload };
};

export const setProjectUid = (payload: string | undefined) => {
  return { type: SET_PROJECT_UID, payload };
};

export const setETCaseworker = (payload: User[] | undefined) => {
  return { type: SET_ET_CASEWORKERS, payload };
};

export const setETCaseworkerLoading = (payload: boolean) => {
  return { type: SET_ET_CASEWORKERS_LOADING, payload };
};

export const setVesselCardData = (payload: GetVesselDataResultV3 | GetVesselDataAreaV2Result) => {
  return { type: SET_VESSEL_CARD_DATA, payload };
};

export const setVesselCardDataLoading = (payload: boolean) => {
  return { type: SET_VESSEL_CARD_DATA_LOADING, payload };
};
