import React, { useState, useCallback, useRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { SdiShip } from '@sdir/sds';
import { SmartSearch } from '@sdir/smartsearch/lib/library';
import { useIntl } from 'react-intl';
import HeaderMenu from '../Organisms/HeaderMenu';
import useIsClickedInside from '../../services/hooks/useIsClickedInside';
import { Routes } from './Routes';
import { ShowTestHeader } from '../../constants/permissions';
import { ToggleContent } from '../Atoms';

interface HeaderState {
  active?: boolean;
}

const Header: React.FC<HeaderState> = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const location = useLocation();
  const { locale } = useIntl();
  const navigate = useNavigate();
  const handleHamburgerClicked = useCallback(() => {
    setIsHamburgerActive(!isHamburgerActive);
  }, [isHamburgerActive]);

  const onClickInsideHandler = useCallback(
    state => {
      if (!state && isHamburgerActive) {
        setIsHamburgerActive(false);
      }
    },
    [isHamburgerActive]
  );

  const navigationCallBack = useCallback(
    (commonAddresseeId: string) => {
      navigate(generatePath(Routes.person, { commonAddresseeId }));
    },
    [history]
  );

  useIsClickedInside({
    wrapperRef,
    callback: onClickInsideHandler,
    turnedOn: isHamburgerActive
  });

  const getProjectPath = useCallback(() => {
    const pathname = location.pathname.split('/');
    const currentPage = pathname[1];
    return currentPage;
  }, [location.pathname]);

  return (
    <>
      <HeaderBar>
        <div ref={wrapperRef}>
          <HamburgerMenu onClick={handleHamburgerClicked}>
            <Bar1 active={isHamburgerActive} />
            <Bar2 active={isHamburgerActive} />
            <Bar3 active={isHamburgerActive} />
            {getProjectPath() === 'et' && (
              <IconContainer>
                <SdiShip color={theme.colors.border} />
                <P>{intl.formatMessage({ id: 'caseworker.page.title' })}</P>
              </IconContainer>
            )}
          </HamburgerMenu>
          <HeaderMenu
            isActive={isHamburgerActive}
            onClose={() => setIsHamburgerActive(!isHamburgerActive)}
          />
        </div>
        <SearchBar>
          <SmartSearch locale={locale} navigationCallBack={navigationCallBack} />
          <ToggleContent toggleName={ShowTestHeader}>
            <EnvironmentName id="test_env">Testmiljø</EnvironmentName>
          </ToggleContent>
        </SearchBar>
      </HeaderBar>
    </>
  );
};

const HeaderBar = styled.div`
  position: relative;
  top: 0;
  height: 10.5rem;
  width: 100%;
  text-align: center;
  background: #004176;
  color: white;
  align-content: center;
  padding: 10px;

  &:has(#test_env) {
    background: #ffb02e;
  }
`;

const SearchBar = styled.div`
  height: 100%;
  display: flex;
`;

const HamburgerMenu = styled.div`
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 50px;
  top: 25%;
  @media only screen and (min-width: 720px) {
    left: 100px;
  }

  @media only screen and (min-width: 1080px) {
    left: 200px;
  }
`;

const Bar1 = styled.div`
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 2px;
  transform: ${(props: HeaderState) =>
    props.active ? `rotate(-45deg) translate(-8px, 7px)` : 'none'};
`;

const Bar2 = styled.div`
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 2px;
  opacity: ${(props: HeaderState) => (props.active ? 0 : 1)};
`;

const Bar3 = styled.div`
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 2px;
  transform: ${(props: HeaderState) =>
    props.active ? 'rotate(45deg) translate(-8px, -8px)' : 'none'};
`;

const IconContainer = styled.div`
  width: 120px;
  display: flex;
  position: absolute;
  left: 150px;
  top: 8%;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: ${({ theme }) => theme.font.size.lg};
  margin-right: 50px;
`;

const P = styled.p`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 2rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.border};
  margin-left: 1rem;
`;

const EnvironmentName = styled.span`
  font-weight: bold;
  font-size: 4rem;
  position: absolute;
  right: 50px;
  top: 25%;
  @media only screen and (min-width: 720px) {
    right: 25px;
  }

  @media only screen and (min-width: 1080px) {
    right: 100px;
  }
`;

export default Header;
