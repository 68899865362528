/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';

import { getProjectCaseWorkersApi, userPermissionApi } from '../../../httpclient';

const useAccess = (projectUid, vesselUid) => {
  const { setError } = useToast();
  const intl = useIntl();

  useEffect(() => {
    if (vesselUid) reloadTeamMembers();
  }, [vesselUid]);

  const { result: teamMembers, loading: teamMembersLoading, callApi: reloadTeamMembers } = useApi({
    apiFunction: () =>
      userPermissionApi.v1UserpermissionGetentitiesusersEntityUidsPost([vesselUid]),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'teammedlemmer' })}: ${err}`);
    }
  });

  const { result: sdirTeam, loading: sdirTeamLoading, callApi: reloadSdirTeam } = useApi({
    apiFunction: () =>
      getProjectCaseWorkersApi.v1GetprojectcaseworkersGetprojectteammembersProjectIdGet(projectUid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'sdirteam' })}: ${err}`);
    }
  });

  return {
    teamMembers,
    teamMembersLoading,
    reloadTeamMembers,
    sdirTeam,
    sdirTeamLoading,
    reloadSdirTeam
  };
};

export default useAccess;
