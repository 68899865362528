import { AppPermission } from '@sdir/auth/lib/types';

interface IAppPermissions {
  ReadAccessToProjects: AppPermission;
  ViewAccessToPerson: AppPermission;
  ViewDraftAndPayment: AppPermission;
  ViewAccessToMergePersons: AppPermission;
}

const serviceIdentifier = 'app-internportal';
// All AppPermissions must have the same service identifier
export const AppPermissions: IAppPermissions = {
  ReadAccessToProjects: {
    name: `${serviceIdentifier}.EtCaseWorkerPage.ReadAccessToProjects`,
    description: 'Access to viewing the case worker page and all projects.'
  },
  ViewAccessToPerson: {
    name: `${serviceIdentifier}.PersonPage.ViewAccessToPerson`,
    description: 'Access to navigate to person page via search results.'
  },
  ViewDraftAndPayment: {
    name: `${serviceIdentifier}.PersonPage.ViewDraftAndPayment`,
    description: 'Access to see applications on person page, with status draft or payment.'
  },
  ViewAccessToMergePersons: {
    name: `${serviceIdentifier}.PersonPage.ViewAccessToMergePersons`,
    description: 'Access to view and use person merge feature on person page.'
  }
};

export const ToggleName = 'interportal_tasks';
export const ToggleAPS = 'internportal_APS';
export const DisableNewSeagoingToggle = 'internportal_disable_new_seagoing';
export const DisableMergePersonsToggle = 'internportal_disable_merge_persons';

export const InspectionToggle = 'internportal_incidents';
export const NewActivityButton = 'internportal_newactivity_button';
export const ShowRequirementListTab = 'fres_requirement_list_show_tab';
export const ShowTilsynTab = 'ET_FRES_TILSYN_TAB';
export const NewInspectionFlow = 'ET_FRES_INSPECTION_NEW_FLOW';
export const ShowTestHeader = 'et_test_env_header';
