import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { DataCard, Loader, DataTable, TextLink } from '@sdir/sds';
import { useDataSorting } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { ManagedUser } from '@sdir/httpclient/lib/clients/core/authorization';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GetProjectResult } from '@sdir/httpclient/lib/clients/et/construction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserAdministrationEditModal from '@sdir/blueprint.et/lib/components/Organisms/UserAdministrationModal/UserAdministrationEditModal';
import { useFeatureToggles } from '../../../services/hooks/useFeatureToggles';

interface CustomerTeamProps {
  teamMembers: ManagedUser[];
  loading: boolean;
  reloadTeamMembers: () => void;
  vessel: GetProjectResult | undefined;
}

const CustomerTeam: React.FC<CustomerTeamProps> = ({
  teamMembers,
  loading,
  vessel,
  reloadTeamMembers
}) => {
  const intl = useIntl();
  const [selectedUserForEdit, setSelectedUserForEdit] = useState<ManagedUser | undefined>();
  const { result: unleashActiveToggles, loading: featureTogglesLoading } = useFeatureToggles();
  const columns = [
    {
      label: intl.formatMessage({ id: 'access.customerteam.name' }),
      key: 'name'
    },
    {
      key: 'email',
      label: intl.formatMessage({ id: 'access.customerteam.email' })
    },
    {
      key: 'permissions',
      label: intl.formatMessage({ id: 'access.customerteam.permissions' })
    },
    {
      label: '',
      key: 'edit'
    }
  ];

  const getTableData = useCallback(
    (data: ManagedUser[]) =>
      data.map(details => {
        const entity = details.entities?.find(e => vessel?.vesselUid && e.uid === vessel.vesselUid);
        return {
          name: details.name,
          email: details.email,
          permissions: entity?.permissions ? entity.permissions.map(p => p.key).join(', ') : '',
          edit: (
            <TextLink
              text=""
              icon={<FontAwesomeIcon icon={faPenToSquare as IconProp} />}
              onClick={() => setSelectedUserForEdit(details)}
              title={intl.formatMessage({ id: 'common.change' })}
            />
          )
        };
      }),
    []
  );

  const { sortedData, setSortedBy } = useDataSorting<any>(getTableData(teamMembers), columns);

  return (
    <>
      <DataCard customHeader>
        <CardHeader>
          <Title>{intl.formatMessage({ id: 'access.customerteam.title' })}</Title>
        </CardHeader>
        {(loading || featureTogglesLoading) && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {teamMembers && (
          <StyledTable>
            <DataTable
              columns={columns}
              data={sortedData}
              sortableKeys={['name', 'email', 'permissions']}
              onHeaderClick={setSortedBy}
            />
          </StyledTable>
        )}
        {selectedUserForEdit && (
          <UserAdministrationEditModal
            setShowEditModal={() => setSelectedUserForEdit(undefined)}
            getInvitedUsersAndUsers={reloadTeamMembers}
            selectedEntity={{ value: vessel?.vesselUid ?? '', name: vessel?.name ?? '' }}
            selectedUser={selectedUserForEdit}
            unleashActiveToggles={unleashActiveToggles}
          />
        )}
      </DataCard>
    </>
  );
};

const LoaderContainer = styled.div``;

const CardHeader = styled.div`
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 3rem;
  border-radius: 6px 6px 0 0;
  button {
    font-family: ${({ theme }) => theme.fonts.stfMedium};
  }
`;

const Title = styled.div`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  margin-right: 3rem;
`;

const StyledTable = styled.div`
  thead {
    background-image: linear-gradient(#ccd9e3, #ccd9e3), linear-gradient(white, white);
    background-repeat: no-repeat;
    background-size: 91% 4%;
    background-position: bottom;
  }
`;

export default CustomerTeam;
