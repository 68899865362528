/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import React from 'react';
import { ProjectDocumentV3 } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { ProjectActivityV3 } from '@sdir/blueprint.et/lib/types/projectActivityTypes';
import { ActivityCard } from '@sdir/blueprint.et/lib/components';
import { useIntl } from 'react-intl';
import { SdiFile, TextLink } from '@sdir/sds';
import { useParams } from 'react-router-dom';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';
import FileTable, { FiletableBackground } from './FileTable';
// eslint-disable-next-line import/no-cycle
import DocumentTaskCard from './DocumentTaskCard';

interface DocumentControlActivityGroupCardProps {
  approvalCalBack?: (uri?: string) => void;
  removeFilterCallback?: () => void;
  requirementsListTabEnabled?: boolean;
  document: ProjectDocumentV3;
  activities: ProjectActivityV3[];
  numberOfHiddenActivities: number;
}

const DocumentControlActivityGroupCard: React.FC<DocumentControlActivityGroupCardProps> = ({
  approvalCalBack,
  removeFilterCallback,
  requirementsListTabEnabled,
  document,
  activities,
  numberOfHiddenActivities
}) => {
  const { description, label, attachments } = document;
  const intl = useIntl();

  const { projectuid } = useParams() as {
    projectuid: string;
  };

  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid });

  return (
    <>
      <BorderWrapper id={document.uri!} areHiddenActivities={numberOfHiddenActivities}>
        <span className="vertical-line" />
        <Wrapper>
          <ActivityCard
            icon={<SdiFile />}
            type={intl.formatMessage({ id: 'documentcard.document' })}
            title={label}
            subTitle={intl.formatMessage({ id: 'documentcard.documentdesc' })}
            description={description}
            activityGroup
          />

          <FiletableBackground>
            {attachments && attachments.length > 0 ? (
              <FileTable files={attachments} downloadFile={downloadByFilename} />
            ) : (
              <p>{intl.formatMessage({ id: 'documentcard.nouploadedfiles' })}</p>
            )}
          </FiletableBackground>
        </Wrapper>
        {activities?.map((activity, i) => {
          return (
            <DocumentTaskCard
              activity={activity as any}
              key={activity.uid}
              isLast={i === activities.length - 1 && numberOfHiddenActivities === 0}
              approvalCalBack={approvalCalBack}
              requirementsListTabEnabled={requirementsListTabEnabled}
            />
          );
        })}
        {numberOfHiddenActivities > 0 ? (
          <HiddenActivities>
            {`+${numberOfHiddenActivities} ${intl.formatMessage({
              id: 'common.activities.oppgaver'
            })}`}
            <TextLink
              text={`${intl.formatMessage({ id: 'common.activities.fjern.filter' })}`}
              onClick={() => removeFilterCallback && removeFilterCallback()}
            />
          </HiddenActivities>
        ) : (
          ''
        )}
      </BorderWrapper>
    </>
  );
};
export default DocumentControlActivityGroupCard;

/* Creates the vertical line border */
const BorderWrapper = styled.div<{ areHiddenActivities: number | undefined }>`
  position: relative;

  .vertical-line {
    border-left: 2px dashed ${({ theme }) => theme.colors.secondary.grey1};
    border-bottom: 2px dashed ${({ theme }) => theme.colors.secondary.grey1};
    width: 10rem;
    display: inline-block;
    position: absolute;
    z-index: -1;
    height: calc(
      100% -
        ${({ areHiddenActivities }) =>
          areHiddenActivities && areHiddenActivities > 0 ? '34px' : '250px'}
    );
    left: 2rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 3px #00000029;
  margin-bottom: 2rem;
  padding: 3rem 5rem 5rem 5rem;
`;

const HiddenActivities = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
  position: relative;
  margin-left: 55px;
  margin-top: -30px;
  margin-bottom: 25px;
  padding-left: 2px;
  font-size: 1.75rem;
  background-color: #f7f7f7;

  button {
    color: #003c69;
    margin-left: 10px;
    text-decoration: none;
  }
`;
